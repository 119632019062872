import React, { Component } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import axios from 'axios';
import PageHelmet from '../component/common/Helmet';
import Header from '../component/header/HeaderFive';
import ScrollToTop from 'react-scroll-up';
import CallAction from '../elements/callaction/CallAction';
import FooterTwo from '../component/footer/FooterTwo';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import localize from '../localize';
import i18n from 'i18next';

class Program extends Component {
  constructor(props) {
    super(props);
    this.programs = props.programs;
    this.config = props.config;
    this.programId = this.props.match.params.programId;
    // this.categoryName = this.props.match.query.categoryName;
    this.programName = new URLSearchParams(this.props.location.search).get(
      'programName'
    );
    this.state = {
      isOpen: false,
    };
    this.getProgram();
    this.i18 = props.t;
  }

  getProgram() {
    const request = axios.get(`/api/v1/public/programs/${this.programId}`);
    request.then((response) => {
      this.program = response.data;
      this.setState(this.state);
    });
  }

  render() {
    return (
      <React.Fragment>

        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle='Service Details'/>
        {/* End Pagehelmet  */}

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                programs={this.programs}/>
        {/* End Header Area  */}
        {/*<Header headertransparent="header--transparent" colorblack="color--black"*/}
        {/*        logoname="logo.png" programs={this.programs}/>*/}


        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--60 pb--110 bg_image "
             data-black-overlay="5"
             style={{ backgroundImage: `url(${((this.program || {}).coverImage || {}).largeUrl || ((this.program || {}).coverImage || {}).originalUrl})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className=" text-center pt--60 breadcrumb-inner">
                  <h2 className="title ">{localize((this.program || {}).name)}</h2>
                  {/*<p style={{color: 'white'}}>{localize((this.program || {}).shortDescription)}</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
            <div className="bodyE">
              <div
                className={(i18n.language === 'en')?"col-lg-12 text-left" : "col-lg-12 text-right"}
                   dangerouslySetInnerHTML={{ __html: (localize((this.program || {}).about))}}>
              </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        <div className="row justify-content-md-center" style={{paddingTop: '64px'}}>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start call To Action  */}
        <CallAction
          showStudentForm={(this.program || {}).hasLocations}
          showSchoolForm={(this.program || {}).schoolProgram}
          defaultProgram={this.programId}
          selectedProgramName={localize((this.program || {}).name)}
          showRegister={this.state.isRegisterOpen}
          showJoin={this.state.isJoinOpen}
          handleShowJoin={(isJoinOpen) => {
            this.setState({
              ...this.state,
              isJoinOpen
            });
          }}
          handleShowRegister={(isRegisterOpen) => {
            this.setState({
              ...this.state,
              isRegisterOpen
            });
          }}/>
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo config={this.config}/>
        {/* End Footer Style  */}

      </React.Fragment>
    );
  }
}

export default withTranslation()(Program);
