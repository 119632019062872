import i18n from "i18next";
import enTranslation from "./translations/en";
import arTranslation from "./translations/ar";
import { initReactI18next } from "react-i18next";


// const enTranslation = JSON.parse(fs.readFileSync("translations/en.json"));

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation :enTranslation
  },
  ar: {
    translation :arTranslation
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;