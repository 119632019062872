import React, { Component, Fragment } from 'react';
import PageHelmet from '../component/common/Helmet';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import CallAction from '../elements/callaction/CallAction';
import FooterTwo from '../component/footer/FooterTwo';
import Breadcrumb from '../elements/common/Breadcrumb';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Team from '../blocks/team/TeamTwo';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import localize from '../localize';
import ContactForm from './ContactForm';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.teamMembers = [];
    this.programs = props.programs;
    this.schoolProgram = this.getSchoolProgram();
    this.config = props.config;
    this.i18 = props.t;
    this.getTeamMembers();
  }

  getSchoolProgram() {
    const schoolPrograms =  this.programs.filter((program) => program.schoolProgram);
    if (schoolPrograms.length > 0) {
      return schoolPrograms[0];
    } else {
      return null;
    }
  }

  getTeamMembers() {
    const request = axios.get(`/api/v1/public/teamMembers?order=order`);
    request.then((response) => {
      this.teamMembers = response.data.results;
      this.setState(this.state);
    });
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  static defaultProps = {
    center: {
      lat: 31.984690983601308,
      lng: 35.83280895566108
    },
    zoom: 14
  };

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle={this.i18('navContact')}/>

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                programs={this.programs}/>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--60 pb--110 bg_image "
             data-black-overlay="5"
             style={{ backgroundImage: `url(${((this.config || {}).contactUsPageCoverImage || {}).largeUrl || ((this.config || {}).contactUsPageCoverImage || {}).originalUrl})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className=" text-center pt--60 breadcrumb-inner">
                  <h2 className="title ">{this.i18('get in touch with us')}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}


        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">

            <div className={(i18n.language === 'ar') ? 'row text-right' : 'row text-left'}>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className={'row'}>
                  {/* Start Single Address  */}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="rn-address">
                      <a target={'_blank'} href={'tel:' + this.config.phoneNumbers[0].value || '#'}>
                        <div className="icon">
                          <FiHeadphones/>
                        </div>
                      </a>
                      <div className="inner">
                        <h4 className="title">{this.i18('Contact With Phone Number')}</h4>
                        {(this.config.phoneNumbers || []).map((phoneNumber, i) => (
                          <p key={i}>
                            <a href={'tel:' + phoneNumber.value}>
                              Tel: {phoneNumber.value}
                            </a>
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* End Single Address  */}

                  {/* Start Single Address  */}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt--25">
                    <div className="rn-address">
                      <a target={'_blank'} href={'mailto:' + this.config.emails[0].value || '#'}>
                        <div className="icon">
                          <FiMail/>
                        </div>
                      </a>
                      <div className="inner">
                        <h4 className="title">{this.i18('Email Address')}</h4>
                        {(this.config.emails || []).map((email, i) => (
                          <p key={i}>
                            <a href={'mailto:' + email.value}>{email.value}</a>
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* End Single Address  */}

                  {/* Start Single Address  */}
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt--25 ">
                    <div className="rn-address">
                      <a target={'_blank'}
                         href={'https://www.google.com/maps/place/Thaka+Jordan/@31.984468,35.8305988,17z/data=!3m1!4b1!4m5!3m4!1s0x151ca18602cf50ad:0x8240495c27f7561e!8m2!3d31.9844635!4d35.8327875'}>
                        <div className="icon">
                          <FiMapPin/>
                        </div>
                      </a>
                      <div className="inner">
                        <h4 className="title">{this.i18('Location')}</h4>
                        <p>
                          <a target={'_blank'}
                             href={'https://www.google.com/maps/place/Thaka+Jordan/@31.984468,35.8305988,17z/data=!3m1!4b1!4m5!3m4!1s0x151ca18602cf50ad:0x8240495c27f7561e!8m2!3d31.9844635!4d35.8327875'}>
                            {(this.config.address || {}).en}
                          </a></p>
                      </div>
                    </div>
                  </div>
                  {/* End Single Address  */}
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <Map
                  google={this.props.google}
                  initialCenter={{
                    lat: 31.984690983601308,
                    lng: 35.83280895566108
                  }}
                  zoom={15}
                >
                  <Marker
                    onClick={this.onMarkerClick}
                    position={{
                      lat: 31.984690983601308,
                      lng: 35.83280895566108
                    }}>
                  </Marker>
                  <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}>
                    <div>
                      Thaka Jordan Head Office UCMAS & JOE
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Team Area  */}
        <div className="rn-team-area d-none ptb--120 bg_color--1">
          <div className="container">
            <div className={(i18n.language === 'ar') ? 'row  text-right' : 'row text-left'}>
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">{this.i18('Managing Team')}</h2>
                  {/*<p>There are many variations of passages of Lorem Ipsum available, but the*/}
                  {/*  majority have suffered alteration.</p>*/}
                </div>
              </div>
            </div>
            <div className={(i18n.language === 'ar') ? 'row  text-right' : 'row text-left'}>
              <Team column="col-lg-3 col-md-6 col-sm-6 col-12  mt--30"
                    teamMembers={this.teamMembers}
                    teamStyle="" item="4"/></div>
            <div className="row justify-content-md-center" style={{ paddingTop: '64px' }}>

              <div className="slide-btn">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link to={'/joinOurTeam'} style={{ cursor: 'pointer' }}
                      className="rn-button-style--2 btn-solid"
                >{this.i18('Join our team')}</Link>
              </div>

            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area pb--120 bg_color--1">
          <div className="container">
            <div className={(i18n.language === 'ar') ? 'row  text-right' : 'row text-left'}>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h2
                  className={'mt--50 mb--50 text-center '}>{this.i18('Send Us Message')} </h2>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        {/*<div className="rn-contact-page ptb--120 bg_color--1">*/}
        {/*  <ContactTwo/>*/}
        {/*</div>*/}
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        {/*<div className="rn-contact-map-area position-relative">*/}
        {/*  <div style={{*/}
        {/*    height: '650px',*/}
        {/*    width: '100%'*/}
        {/*  }}>*/}
        {/*    <GoogleMapReact*/}
        {/*      defaultCenter={this.props.center}*/}
        {/*      defaultZoom={this.props.zoom}*/}
        {/*    >*/}
        {/*      <AnyReactComponent*/}
        {/*        lat={59.955413}*/}
        {/*        lng={30.337844}*/}
        {/*        text="My Marker"*/}
        {/*      />*/}
        {/*    </GoogleMapReact>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* End Contact Map  */}


        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start call To Action  */}
        <CallAction
          showContactUs={false}
          showStudentForm={true}
          defaultProgram={(this.schoolProgram || {})._id}
          selectedProgramName={localize((this.schoolProgram || {}).name)}
          showSchoolForm={this.schoolProgram}/>
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo config={this.config}/>
        {/* End Footer Style  */}


      </React.Fragment>
    );
  }
}

export default withTranslation()(GoogleApiWrapper({
  apiKey: ('AIzaSyAhyetD7gcDe4Q-b84X9NDVKbr8Me5aADk')
})(Contact));
