import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaEnvelope, FaPhone } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import localize from '../../localize';



const TeamTwo = (props) => {

  return (
    <div className={(i18n.language === 'ar') ? 'row text-right' : 'row text-left'}>
      {(props.teamMembers || []).map((teamMember, i) => (
        <div className={`${props.column}`} key={i}>
          <div className={`text-center team-static ${props.teamStyle}`}>
            {/*<div className="thumbnail">*/}
            {/*  <img src={(teamMember.image || {}).originalUrl} alt={(teamMember.name || {}).en}/>*/}
            {/*</div>*/}
            <div className="inner" style={{border:'1px solid #f6e04c' , minHeight:200 , borderRadius:5 , padding:25 , margin:10}}>
              <div className="content">
                <h4 className="title">{localize(teamMember.name || {})}</h4>
                <p className="designation">{localize(teamMember.position || {})}</p>
              </div>
              <ul className="social-transparent liststyle d-flex justify-content-center">
                {teamMember.email && (
                  <li><a href={`mailto:${teamMember.email}`}><FaEnvelope/></a></li>
                )}
                {teamMember.phoneNumber && (
                  <li><a href={`tell:${teamMember.phoneNumber}`}><FaPhone/></a></li>
                )}
                {teamMember.linkedIn && (
                  <li><a href={`${teamMember.linkedIn}`}><FaLinkedinIn/></a></li>
                )}
                {teamMember.facebook && (
                  <li><a href={`${teamMember.facebook}`}><FaFacebookF/></a></li>
                )}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default withTranslation()(TeamTwo);
