import React from 'react';
import { Link } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import localize from '../../localize';
import i18next from 'i18next';

const callToActionTitle1 = {
  fontSize: '40px',
  fontWeight: 500,
  margin: 0,
};
const callToActionTitle2 = {
  fontSize: '60px',
  margin: 0,
  padding: 0,
  textTransform: 'uppercase',
  fontWeight: 700,
};

const CallAction = (props) => {

  const i18 = props.t;

  return (
    <>
      <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--50">
        <div className="container">
          <div className="row">
            <div className={'col-lg-6 inner text-center'}>
              <h3 style={callToActionTitle1}>{i18('READY TO DO THIS')}</h3>
              <h4 style={callToActionTitle2}>{i18('Lets get to work')}</h4>
            </div>
            <div className={'col-lg-6 inner text-center'}>
              <div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/*<a className="rn-button-style--2" onClick={() => {*/}
                {/*  props.handleShowRegister(true);*/}
                {/*}}><span>Register a student</span></a>*/}

                {props.showSchoolForm && props.schoolFormTitle && (
                  <Link to={'/registerSchool'}
                        className="rn-button-style--2"><span>{props.schoolFormTitle}</span></Link>
                )}
                {props.showSchoolForm && i18next.language !== 'ar' && !props.schoolFormTitle && (
                  <Link to={'/registerSchool/' + props.defaultProgram + '?programName=' + props.selectedProgramName}
                        className="rn-button-style--2"><span>Become a {props.selectedProgramName} School</span></Link>
                )}
                {props.showSchoolForm && i18next.language === 'ar' && !props.schoolFormTitle && (
                  <Link to={'/registerSchool/' + props.defaultProgram + '?programName=' + props.selectedProgramName}
                        className="rn-button-style--2"><span> مدرسة معتمدة لتعليم {props.selectedProgramName}</span></Link>
                )}

                {props.showStudentForm && (
                  <Link to={'/register?program=' + props.defaultProgram}
                        className="rn-button-style--2"><span>{i18('Register a student')}</span></Link>
                )}

                {' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/*<a className="rn-button-style--2" onClick={() => {*/}
                {/*  props.handleShowJoin(true);*/}
                {/*}}><span>Join our team</span></a>*/}

                <Link to={'/joinOurTeam'} className="rn-button-style--2"><span>{i18('Join our team')}</span></Link>
                {props.showContactUs && (
                <Link to={'/contact'} className="rn-button-style--2"><span>{i18('Contact Us')}</span></Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(CallAction);
