import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';


class Header extends Component {
  constructor(props) {
    super(props);
    this.programs = props.programs;
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', function () {
      console.log('All assets are loaded');
    });
  }

  menuTrigger() {
    document.querySelector('.header-wrapper')
      .classList
      .toggle('menu-open');
  }

  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper')
      .classList
      .remove('menu-open');
  }


  render() {
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu')
            .classList
            .toggle('active');
          this.classList.toggle('open');
        };
      }
    }
    const { color = 'default-color' } = this.props;
    let logoUrl = <img src="/assets/images/logo/thaka.webp" alt="Trydo"/>;

    // let logoUrl;
    // if(logo === 'light'){
    //     logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
    // }else if(logo === 'dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-light'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
    // }else{
    //     logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
    // }

    return (
      <header className={`header-area formobile-menu header--transparent ${color}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                {logoUrl}
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li><Link to="/">Home</Link></li>
                {this.programs.map((program) => {
                  if (program.hasLocations || program.hasStudentCorner) {
                    return (
                      <li className="has-droupdown" key={program._id}><Link to="#">{program.name.en}</Link>
                        <ul className="submenu">
                          <li><Link to={'/programs/' + program._id}>About</Link></li>
                          {program.hasLocations && (
                            <li><Link
                              to={'/locations/' + program._id}>Nearest {program.name.en} Center</Link>
                            </li>
                          )}
                          {program.hasStudentCorner && (
                            <li><Link to={'/studentCorner/' + program._id}>Student Corner</Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li key={program._id}><Link to={'/programs/' + program._id}>{program.name.en}</Link></li>);
                  }
                })}
                <li><Link to="/mediaCenter">Media Center</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </nav>
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu/></span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX/></span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
