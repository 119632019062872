import React from 'react';

const arTranslations = {
  // Menu
  'navHome': 'الرئيسية',
  'navAbout':'من نحن',
  'navAboutUCMAS':'عن يوسي ماس',
  'navNearest':'أقرب',
  'navCenter':'مركز',
  'navStudentCorner':'زاوية الطالب',
  'navMediaCenter':'مركز الميديا',
  'navContact':  'اتصل بنا' ,
  // Slider
  'sliderTitle': 'ذكاء',
  'sliderSubTitle': 'المستقبل يبدأ الان..',
  // Programs
  'readMore': 'اقرأ المزيد..',
  //Global
  'About':'من نحن',
  'READY TO DO THIS' : 'جاهز لتقوم بذلك؟',
  'Lets get to work':'هيا إلى العمل!' ,
  'Join our team' : 'انضم إلى فريقنا' ,
  'Register a student' : 'تسجيل طالب',
  'Gallery':'مركز الميديا',
  'get in touch with us':'تواصل معنا',
  'Contact With Phone Number' : 'تواصل عبر أرقام الاتصال',
  'Email Address' : 'البريد الالكتروني',
  'Location':'العنوان',
  'Managing Team' : 'فريق العمل',
  'Send Us Message':'ارسل رسالة الينا',
  'First Name' : 'الاسم الأول',
  'Enter your first name' : 'أدخل الاسم الاول',
  'Last Name' : 'الاسم الأخير',
  'Enter your last name' : 'أدخل الاسم الأخير',
  'Enter a valid email address' : 'أدخل بريد الكتروني صالح',
  'Phone Number' : 'رقم الهاتف',
  'Enter phone number' : 'أدخل رقم الهاتف',
  'Subject' : 'العنوان',
  'Enter message subject' : 'أدخل عنوان الرسالة',
  'Message' : 'الرسالة',
  'Send Message' : 'ارسال الرسالة',




};

export default arTranslations;
