import axios from 'axios';
import fs from 'fs';


export const getProgram = async (programId) => {
  try {
    const response = await axios.get(
      `/api/v1/public/programs/${programId}`,
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};


export const getLocations = async (programId) => {
  try {
    const response = await axios.get(
      `/api/v1/public/centers?programId=${programId}`,
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};


export const submitFormContent = async (formData) => {
  try {
    const response = await axios.post(
      `/api/v1/public/formRequests`,
      formData
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export const submitFileUpload = async (file) => {
  try {
    const response = await axios.post('/api/v1/public/formRequests/fileUpload', file);
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

