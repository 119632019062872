import React, { useEffect, useState } from 'react';
import { submitFormContent } from '../apiServices';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

function ContactForm(props) {
  const t = props.t;
  const [form, setForm] = useState({});

  const handleFormChange = (event) => {
    const newForm = { ...form };
    newForm[event.target.name] = event.target.value;
    setForm(newForm);
  };

  const handleFormSubmit = () => {
    const formData = {
      form: 'contactUs',
      data: form
    };
    submitFormContent(formData)
      .then((response) => {
        console.log('form', response);
      });
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridFirstName">
            <Form.Label>{t('First Name')}</Form.Label>
            <Form.Control value={form.firstName} name="firstName" onChange={handleFormChange} type="text"
                          placeholder={t('Enter your first name')}/>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Label>{t('Last Name')}</Form.Label>
            <Form.Control value={form.lastName} name="lastName" onChange={handleFormChange} type="text" placeholder={t('Enter your last name')}/>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>{t('Email Address')}</Form.Label>
            <Form.Control type="email" value={form.email} name="email" onChange={handleFormChange}
                          placeholder={t('Enter a valid email address')}/>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>{t('Phone Number')}</Form.Label>
            <Form.Control value={form.phoneNumber} name="phoneNumber" onChange={handleFormChange} type="text" placeholder={t('Enter phone number')}/>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formGridAddress1">
          <Form.Label>{t('Subject')}</Form.Label>
          <Form.Control value={form.subject} name="subject" onChange={handleFormChange} placeholder={t('Enter message subject')}/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>{t('Message')}</Form.Label>
          <Form.Control value={form.message} name="message" onChange={handleFormChange} as="textarea" rows={3}/>
        </Form.Group>
        <div style={{
          width: '100%',
          textAlign: 'center'
        }}>
          <div className="slide-btn">
            <Button onClick={handleFormSubmit} type={'submit'}  style={{ cursor: 'pointer' }} className="rn-button-style--2 btn-solid"
            >{t('Send Message')}</Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default withTranslation()(ContactForm);
