import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import CallAction from '../elements/callaction/CallAction';
import FooterTwo from '../component/footer/FooterTwo';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Nav, Tab, Accordion, Card } from 'react-bootstrap';
import axios from 'axios';

class StudentCorner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
    this.programId = this.props.match.params.programId;
    this.programs = props.programs;
    this.config = props.config;
    this.getProgram();
    this.getLevels();
  }


  getProgram() {
    const request = axios.get(`/api/v1/public/programs/${this.programId}`);
    request.then((response) => {
      this.program = response.data;
      this.setState(this.state);
    });
  }

  getLevels() {
    const request = axios.get(`/api/v1/public/levels?programId=${this.programId}`);
    request.then((response) => {
      this.levels = response.data.results;
      console.log(this.levels);

      this.selectedLevelId = this.levels[0]._id;
      this.setState(this.state);
    });
  }


  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='Student Corner'/>

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                programs={this.programs}/>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--60 pb--110 bg_image "
             data-black-overlay="5"
             style={{ backgroundImage: `url(${((this.program || {}).coverImage || {}).largeUrl || ((this.program || {}).coverImage || {}).originalUrl})` }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className=" text-center pt--60 breadcrumb-inner">
                  <h2 className="title ">{((this.program || {}).name || {}).en}</h2>
                  <p
                    style={{ color: 'white' }}>{((this.program || {}).shortDescription || {}).en}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Breadcrump Area */}
        {/*<Breadcrumb title={'Student Corner'}/>*/}
        {/* End Breadcrump Area */}

        {/* Start Student Corner Area  */}
        <div className="rn-contact-top-area ptb--20 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <Tab.Container id="left-tabs-example" defaultActiveKey={this.selectedLevelId}>
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        {(this.levels || []).map((level) => (
                          <Nav.Item>
                            <Nav.Link  eventKey={level._id}>
                              <div style={{ color: 'black' }}>
                                {level.name.en}
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        {(this.levels || []).map((level) => (
                          <Tab.Pane  eventKey={level._id}>
                            <Accordion defaultActiveKey="0">
                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                  Worksheets
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>
                                    <div className="row">
                                      {(level.worksheets || []).map((item)  => (
                                        <div className="col-sm-12">
                                          <div className="">
                                            <div className="" style={{padding:'0.4rem'}}>
                                              <div className={'row'} style={{verticalAlign: 'middle'}}>
                                                {/*<div className={'col-1'}><img alt="file" src={'https://icons-for-free.com/iconfiles/png/512/document+file+files+folder+pdf+icon-1320167966357449593.png'} /> </div>*/}
                                                <div className={'col-9'}><p style={{fontSize:'16px' }} className="card-text">{item.name.en}</p></div>
                                                <div className={'col-2'}><a href={item.url} target="_blank" className="btn btn-success">Open</a></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                  Competition Sheets
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>
                                    <div className="row">
                                      {(level.competitions || []).map((item)  => (
                                        <div className="col-sm-12">
                                          <div className="">
                                            <div className="" style={{padding:'0.4rem'}}>
                                              <div className={'row'} style={{verticalAlign: 'middle'}}>
                                                {/*<div className={'col-1'}><img alt="file" src={'https://icons-for-free.com/iconfiles/png/512/document+file+files+folder+pdf+icon-1320167966357449593.png'} /> </div>*/}
                                                <div className={'col-9'}><p style={{fontSize:'16px' }} className="card-text">{item.name.en}</p></div>
                                                <div className={'col-2'}><a href={item.url} target="_blank" className="btn btn-success">Open</a></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
        {/* End Student Corner Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start call To Action  */}
        <CallAction
          ref={this.actionView}
          showRegister={this.state.isRegisterOpen}
          showJoin={this.state.isJoinOpen}
          handleShowJoin={(isJoinOpen) => {
            this.setState({
              ...this.state,
              isJoinOpen
            });
          }}
          handleShowRegister={(isRegisterOpen) => {
            this.setState({
              ...this.state,
              isRegisterOpen
            });
          }}/>
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo config={this.config}/>
        {/* End Footer Style  */}

      </React.Fragment>
    );
  }
}

export default StudentCorner;
