import React, { useEffect, useState } from 'react';
import { submitFormContent } from '../apiServices';

function RegisterSchoolForm(props) {

  const [form, setForm] = useState({
    city: 'Amman',
  });
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (props.show) {
      setForm({
        city: 'Amman',
        program: props.defaultProgram,
      });
    }
  }, [props.defaultProgram]);


  const handleFormChange = (event) => {
    const newForm = { ...form };
    newForm[event.target.name] = event.target.value;
    setForm(newForm);
  };

  const handleFormSubmit = () => {
    if (!form.schoolName) {
      alert('Please fill all fields');
      return;
    }
    if (!form.name) {
      alert('Please fill all fields');
      return;
    }
    if (!form.phoneNumber) {
      alert('Please fill all fields');
      return;
    }
    const formData = {
      form: 'school',
      data: form
    };
    submitFormContent(formData)
      .then((response) => {
        setShowForm(false);
        console.log('form', response);
      });

  };



  return (

    <div className="row row--35 align-items-center text-center justify-content-center">
      <div className="col-lg-7">

        <div className="form-wrapper" style={{ margin: '50px 15px' }}>
          {showForm?(
            <form>

            <h5 className="text-left">Please fill this form and we will contact you within 2 working days.</h5>
            <div>
              <label htmlFor="schoolName" className={'text-left'} style={{ fontSize: '15px' }}>
                School Name</label>
              <input
                name="schoolName" id="schoolName" value={form.schoolName} onChange={handleFormChange} required
                type="text"/>
            </div>
            <div>
              <label htmlFor="name" className={'text-left'} style={{ fontSize: '15px' }}>Contact Person Name</label>
              <input
                name="name" id="name" value={form.name} onChange={handleFormChange} required
                type="text"/>
            </div>
            <div>
              <label htmlFor="email" className={'text-left'} style={{ fontSize: '15px' }}>
                Email</label>
              <input
                name="email" id="email" value={form.email} onChange={handleFormChange}
                type="email" placeholder="(optional)"/>
            </div>
            <div>
              <label htmlFor="program" className={'text-left'} style={{ fontSize: '15px' }}>
                Program</label>
              <select
                name="program" id="program" value={form.program}
                onChange={handleFormChange}>
                {props.programs.filter((program) => program.schoolProgram).map((program) => (
                  <option value={program._id}>{program.name.en}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="phoneNumber" className={'text-left'} style={{ fontSize: '15px' }}>
                Phone Number</label>
              <input
                name="phoneNumber" id="phoneNumber" value={form.phoneNumber}
                onChange={handleFormChange}
                type="phone" placeholder=""/>
            </div>
            <div>
              <label htmlFor="notes" className={'text-left'} style={{ fontSize: '15px' }}>
                Notes</label>
              <textarea
                placeholder="(optional)"
                name="notes" id="notes" value={form.notes} onChange={handleFormChange}
              />
            </div>

            <button onClick={handleFormSubmit} className="rn-button-style--2 btn-solid" type="submit" value="submit"
                    name="submit" id="mc-embedded-subscribe">Submit
            </button>
          </form>
          ):(
          <h2>Thank you.</h2>
          )}

        </div>
      </div>
    </div>
  );
}

export default RegisterSchoolForm;
