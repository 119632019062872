import React, { useEffect, useState } from 'react';
import { submitFileUpload, submitFormContent } from '../apiServices';
import { Checkbox, FormControlLabel } from '@material-ui/core';

function JoinTeamForm(props) {

  const [form, setForm] = useState({
    city: 'Amman',
  });
  const [uploadBtnTitle, setUploadBtnTitle] = useState('Upload Your CV (PDF/Word )');

  useEffect(() => {
    if (props.show) {
      setForm({
        city: 'Amman',
        program: props.defaultProgram,
      });
    }
  }, [props.defaultProgram]);

  const handleFormChange = (event) => {
    const newForm = { ...form };
    newForm[event.target.name] = event.target.value;
    setForm(newForm);
  };

  const handleUploadFile = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setUploadBtnTitle('Uploading..');
      const formdata = new FormData();
      formdata.append('file', selectedFile, event.target.value);
      submitFileUpload(formdata)
        .then((response) => {
          if (response == null) {
            setUploadBtnTitle('Upload error, Try again');
          } else {
            const newForm = { ...form };
            newForm['file'] = response.publicUrl;
            setForm(newForm);
            setUploadBtnTitle('CV file uploaded');
          }
        })
        .catch(() => {
          setUploadBtnTitle('Upload error, Try again');
        });
    }
  };


  const handleCheckBoxChange = (day) => {
    const newOffDays = [...(form.offDays || [])];
    const indexOfSelected = newOffDays.indexOf(day);
    if (indexOfSelected === -1) {
      newOffDays.push(day);
    } else {
      newOffDays.splice(indexOfSelected, 1);
    }
    setForm({
      ...form,
      offDays: newOffDays
    });

  };

  const handleFormSubmit = () => {
    if (!form.name) {
      alert('Please fill all fields');
      return;
    }
    if (!form.phoneNumber) {
      alert('Please fill all fields');
      return;
    }
    if (!form.file) {
      alert('Please fill all fields');
      return;
    }
    if (!form.offDays || form.offDays.length === 0) {
      alert('Please fill all fields');
      return;
    }
    const formData = {
      form: 'joinUs',
      data: form
    };
    submitFormContent(formData)
      .then((response) => {
        console.log('form', response);
      });
  };

  const weekDays = [
    { day: 'All days, I\'m unemployed' },
    { day: 'Friday' },
    { day: 'Saturday' },
    { day: 'Sunday' },
    { day: 'Monday' },
    { day: 'Tuesday' },
    { day: 'Wednesday' },
    { day: 'Thursday' },
  ];

  console.log(form);

  return (
    <div className="row row--35 align-items-center text-center justify-content-center">
      <div className="col-lg-7">

        <div className="form-wrapper" style={{ margin: '50px 15px' }}>
          <form>

            <h5 className="text-left">Please fill this form and we will contact you when a position
              is vacant.</h5>
            <div>
              <label htmlFor="name" className={'text-left'} style={{ fontSize: '15px' }}>Full
                Name</label>
              <input
                name="name" id="name" value={form.name} onChange={handleFormChange} required
                type="text"/>
            </div>
            <div>
              <label htmlFor="dateOFBirth" className={'text-left'} style={{ fontSize: '15px' }}>
                Date of birth</label>
              <input
                name="date" id={'dateOFBirth'} value={form.date} onChange={handleFormChange}
                required
                type="date" placeholder=""/>
            </div>
            <div>
              <label htmlFor="email" className={'text-left'} style={{ fontSize: '15px' }}>
                Email</label>
              <input
                name="email" id="email" value={form.email} onChange={handleFormChange}
                type="email" placeholder=""/>
            </div>
            <div>
              <label htmlFor="phoneNumber" className={'text-left'} style={{ fontSize: '15px' }}>
                Phone Number</label>
              <input
                name="phoneNumber" id="phoneNumber" value={form.phoneNumber}
                onChange={handleFormChange}
                type="phone" placeholder=""/>
            </div>

            <div>
              <label htmlFor="city" className={'text-left'} style={{ fontSize: '15px' }}>
                City</label>
              <select
                name="city" id="city" value={form.city}
                onChange={handleFormChange}>
                <option selected>Amman</option>
                <option>Karak</option>
                <option>Madaba</option>
                <option>Irbed</option>
                <option>Zarqa</option>
                <option>Salt</option>
                <option>Huson</option>
                <option>Fuheis</option>
                <option>Ramtha</option>
                <option>Jerash</option>
                <option>Ajloun</option>
                <option>Wadi Mousa</option>
                <option>Maan</option>
                <option>Tafileh</option>
                <option>Aqaba</option>
                <option>Mafraq</option>
                {/*{cities.map((city) => (*/}
                {/*  <option value={city}>{city}</option>*/}
                {/*))}*/}
              </select>
            </div>
            <div>
              <label htmlFor="position" className={'text-left'} style={{ fontSize: '15px' }}>
                Position </label>
              <select
                name="position" id="position" value={form.position}
                onChange={handleFormChange}>
                <option selected>Administration</option>
                <option>Training</option>
                <option>Marketing</option>
                <option>Finance</option>
                {/*{cities.map((city) => (*/}
                {/*  <option value={city}>{city}</option>*/}
                {/*))}*/}
              </select>
            </div>
            {form.position === 'Training' && (
              <div>
                <label htmlFor="program" className={'text-left'} style={{ fontSize: '15px' }}>
                  Program</label>
                <select
                  name="program" id="program" value={form.program}
                  onChange={handleFormChange}>
                  {props.programs.filter((program) => program.hasLocations || program.schoolProgram)
                    .map((program) => (
                      <option value={program._id}>{program.name.en}</option>
                    ))}
                </select>
              </div>
            )}
            <div>
              <label htmlFor="offDays" className={'text-left mt-20'} style={{ fontSize: '15px' }}>
                What are your current days off?</label>


              {(weekDays || []).map((item) =>
                <div key={item.day} className="col-sm-12 col-md-12 col-lg-12 text-left ">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={item.day}
                        checked={(form.offDays  || []).indexOf(item.day) !== -1}
                        onChange={() => handleCheckBoxChange(item.day)}
                        value={item.day}/>
                    }
                    label={item.day}
                  />

                </div>
              )}

            </div>

            <div style={{ paddingBottom: '12px',  paddingTop: '20px', }}>
              <label htmlFor="cvFile" className={'text-left'}>
                <div className="btn btn-secondary">{uploadBtnTitle}</div>
              </label>
              <input
                autoFocus
                hidden
                name="cvFile"
                id={'cvFile'}
                accept={'application/pdf,application/msword,.doc,.docx'}
                required
                onChange={handleUploadFile}
                type="file"/>
            </div>

            <div>
              <label htmlFor="notes" className={'text-left'} style={{ fontSize: '15px' }}>
                Notes</label>
              <textarea
                name="notes" id="notes" value={form.notes} onChange={handleFormChange}
              />
            </div>

            <button onClick={handleFormSubmit} className="rn-button-style--2 btn-solid"
                    value="submit"
                    name="submit" id="mc-embedded-subscribe">Submit
            </button>
          </form>

        </div>


      </div>
    </div>
  );
}

export default JoinTeamForm;
