import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../elements/common/Breadcrumb';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import FooterTwo from '../component/footer/FooterTwo';
import axios from 'axios';
import RegisterSchoolForm from './RegisterSchoolForm';

class RegisterSchool extends Component {
  constructor(props) {
    super(props);
    this.programs = props.programs;
    this.config = props.config;
    this.programId = this.props.match.params.programId;
    // this.categoryName = this.props.match.query.categoryName;
    this.programName = new URLSearchParams(this.props.location.search).get(
      'programName'
    );
    this.getProgram();
  }

  getProgram() {
    if (this.programId) {
      const request = axios.get(`/api/v1/public/programs/${this.programId}`);
      request.then((response) => {
        this.program = response.data;
        this.programName = response.data.name.en;
        this.setState(this.state);
      });
    }

  }

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle={`Become ${this.programName} School`}/>

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                programs={this.programs}/>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        {this.programName && (
          <Breadcrumb title={`Become ${this.programName} School`}/>
        )}
        {!this.programName && (
          <Breadcrumb title={`Register School`}/>
        )}
        {/* End Breadcrump Area */}

        {/* Start Form Area  */}
        <div className="contact-form--1">
          <div className="container">
            <RegisterSchoolForm programs={this.programs} defaultProgram={this.programId} />
          </div>
        </div>
        {/* End Form Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Footer Style  */}
        <FooterTwo config={this.config} />
        {/* End Footer Style  */}

      </React.Fragment>
    );
  }
}

export default RegisterSchool;
