import React, { useEffect, useRef, useState } from 'react';
import PageHelmet from '../component/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import CallAction from '../elements/callaction/CallAction';
import FooterTwo from '../component/footer/FooterTwo';
import Breadcrumb from '../elements/common/Breadcrumb';
import Gallery from 'react-grid-gallery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import localize from '../localize';

function MediaCenter(props) {
  const actionView = useRef();
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isJoinOpen, setIsJoinOpen] = useState(false);
  const [albums, setAlbums] = useState([]);
  const [images, setImages] = useState([]);
  console.log('props', props);

  const { t } = props;

  useEffect(() => {
    const { albumId } = props.match.params;
    if (albumId == null) {
      getParentAlbums();
    } else {
      getSelectedAlbum(albumId);
    }
  }, [props.match.params]);

  useEffect(() => {
    setAlbums([]);
    setImages([]);
    if ((selectedAlbum || {}).subsCount === 0) {
      getImages();
    } else {
      getSubsAlbums();
    }
  }, [selectedAlbum]);

  const getParentAlbums = () => {
    const request = axios.get('/api/v1/public/albums/parents');
    request.then((response) => {
      setAlbums(response.data.results);
    });
  };

  const getSubsAlbums = () => {
    const request = axios.get('/api/v1/public/albums/listSubs', {
      params: {
        albumId: (selectedAlbum || {})._id,
      }
    });
    request.then((response) => {
      setAlbums(response.data.results);
    });
  };

  const getSelectedAlbum = (albumId) => {
    const request = axios.get(`/api/v1/public/albums/${albumId}`);
    request.then((response) => {
      setSelectedAlbum(response.data);
    });
  };

  const getImages = () => {
    const request = axios.get('/api/v1/public/images', {
      params: {
        albumId: (selectedAlbum || {})._id,
      }
    });
    request.then((response) => {
      setImages(response.data.results.map((image) => ({
        caption: (image.title || {}).en,
        src: image.media.largeUrl || image.media.originalUrl,
        thumbnail: image.media.thumbnailUrl || image.media.originalUrl,
      })));
    });
  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle={t('Gallery')}/>

      {/* Start Header Area  */}
      <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
              programs={props.programs}/>
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={t('Gallery')} subtitle={localize((selectedAlbum || {}).name || {})}/>

      {/* Start Content Area  */}
      <div className="rn-contact-top-area ptb--60 bg_color--5">
        <div className="container">
          {(selectedAlbum == null || selectedAlbum.subsCount > 0) ? (
            <div className="row " id={'photography-index-page'}>
              {(albums || []).map((album) => (
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <Link
                    to={`/mediaCenter/${album._id}`} style={{ border: 0 }}
                    className="album block">

                    {(album.coverImages || []).map((image) => (
                      <span className="thumb">
                        <span
                          className="polaroid shadow-5"
                          style={{ backgroundImage: `url('${image.thumbnailUrl || image.originalUrl}')` , left:'15%' }}>
                        </span>
                      </span>
                    ))}

                    <h5 style={{position:'absolute' , bottom:'35px', border: '1px solid #dedede',
                      borderRadius: '0px 0px 10px 10px',
                      padding: '10px',
                      background: '#fff',
                      boxShadow: '0 3px 5px rgb(0 0 0 / 40%)',
                      width:'100%',
                      textAlign:'center'
                    }}>
                      {localize((album || {}).name || {})}
                    </h5>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 pt--20 pl--30">
                <Gallery images={(images || [])}/>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start call To Action  */}
      <CallAction
        ref={actionView}
        showRegister={isRegisterOpen}
        showJoin={isJoinOpen}
        handleShowJoin={(mIsJoinOpen) => {
          setIsJoinOpen(mIsJoinOpen);
        }}
        handleShowRegister={(mIsRegisterOpen) => {
          setIsRegisterOpen(mIsRegisterOpen);
        }}/>
      {/* End call To Action  */}

      {/* Start Footer Style  */}
      <FooterTwo config={props.config}/>
      {/* End Footer Style  */}

    </React.Fragment>
  );
}

export default withTranslation()(MediaCenter);
