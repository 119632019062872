import React, { useEffect, useState } from 'react';
import { submitFormContent } from '../apiServices';

function RegisterStudentForm(props) {

  const [form, setForm] = useState({
    city: 'Amman',
    program: ((props.programs || [])[0] || {})._id,
  });
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (props.show) {
      if (this.props.defaultProgram) {
        setForm({
          city: 'Amman',
          program: props.defaultProgram,
        });
      } else {
        setForm({
          city: 'Amman',
          program: ((props.programs || [])[0] || {})._id,
        });
      }
    }
  }, [props.defaultProgram, props.show]);


  const handleFormChange = (event) => {
    const newForm = { ...form };
    newForm[event.target.name] = event.target.value;
    setForm(newForm);
  };

  const handleFormSubmit = () => {
    if (!form.name) {
      alert('Please fill all fields');
      return;
    }
    if (!form.date) {
      alert('Please fill all fields');
      return;
    }
    if (!form.program) {
      alert('Please fill all fields');
      return;
    }
    if (!form.phoneNumber) {
      alert('Please fill all fields');
      return;
    }
    const formData = {
      form: 'student',
      data: form
    };

    submitFormContent(formData)
      .then((response) => {
        setShowForm(false);
        console.log('form', response);
      });
  };

  return (
    <div className="row row--35 align-items-center text-center justify-content-center">
      <div className="col-lg-7">

        <div className="form-wrapper" style={{ margin: '50px 15px' }}>
          {showForm?(
            <form>
              <h5 className="text-left">Please fill this form and we will contact you within 2 working days.</h5>
              <div>
                <label htmlFor="name" className={'text-left'} style={{ fontSize: '15px' }}>Full
                  Name</label>
                <input
                  name="name" id="name" value={form.name} onChange={handleFormChange} required
                  type="text"/>
              </div>
              <div>
                <label htmlFor="dateOFBirth" className={'text-left'} style={{ fontSize: '15px' }}>
                  Date of birth</label>
                <input
                  name="date" id={'dateOFBirth'} value={form.date} onChange={handleFormChange}
                  required
                  type="date" placeholder=""/>
              </div>
              <div>
                <label htmlFor="grade" className={'text-left'} style={{ fontSize: '15px' }}>
                  Current Grade</label>
                <input
                  name="grade" id="grade" value={form.grade} onChange={handleFormChange}
                  type="text" placeholder="(optional)"/>
              </div>
              <div>
                <label htmlFor="program" className={'text-left'} style={{ fontSize: '15px' }}>
                  Program</label>
                <select
                  name="program" id="program" value={form.program}
                  onChange={handleFormChange}>
                  {props.programs.filter((program) => program.hasLocations).map((program) => (
                    <option value={program._id}>{program.name.en}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="city" className={'text-left'} style={{ fontSize: '15px' }}>
                  City</label>
                <select
                  name="city" id="city" value={form.city || ''}
                  onChange={handleFormChange}>
                  <option >Amman</option>
                  <option >Karak</option>
                  <option >Madaba</option>
                  <option >Irbed</option>
                  <option >Zarqa</option>
                  <option >Salt</option>
                  <option >Huson</option>
                  <option >Fuheis</option>
                  <option >Ramtha</option>
                  <option >Jerash</option>
                  <option >Ajloun</option>
                  <option >Wadi Mousa</option>
                  <option >Maan</option>
                  <option >Tafileh</option>
                  <option >Aqaba</option>
                  <option >Mafraq</option>
                </select>
              </div>
              <div>
                <label htmlFor="area" className={'text-left'} style={{ fontSize: '15px' }}>
                  Area</label>
                <input
                  name="area" id="area" value={form.area} onChange={handleFormChange}
                  type="text" placeholder=""/>
              </div>
              <div>
                <label htmlFor="email" className={'text-left'} style={{ fontSize: '15px' }}>
                  Email</label>
                <input
                  name="email" id="email" value={form.email} onChange={handleFormChange}
                  type="email" placeholder="(optional)"/>
              </div>
              <div>
                <label htmlFor="phoneNumber" className={'text-left'} style={{ fontSize: '15px' }}>
                  Phone Number</label>
                <input
                  name="phoneNumber" id="phoneNumber" value={form.phoneNumber} onChange={handleFormChange}
                  type="phone" placeholder=""/>
              </div>

              <div>
                <label htmlFor="notes" className={'text-left'} style={{ fontSize: '15px' }}>
                  Notes</label>
                <textarea
                  placeholder="(optional)"
                  name="notes" id="notes" value={form.notes} onChange={handleFormChange}
                />
              </div>
              {/*<div>*/}
              {/*  <label htmlFor="school" className={'text-left'} style={{ fontSize: '15px' }}>*/}
              {/*    School</label>*/}
              {/*  <input*/}
              {/*    name="school" id="school" value={form.school} onChange={handleFormChange}*/}
              {/*    type="text" placeholder=""/>*/}
              {/*</div>*/}

              <button onClick={handleFormSubmit} className="rn-button-style--2 btn-solid" type="submit" value="submit"
                      name="submit" id="mc-embedded-subscribe">Submit
              </button>
            </form>
          ):(
            <h2>Thank you.</h2>
          )}


        </div>


      </div>
    </div>

  );
}

export default RegisterStudentForm;
