import React from 'react';
import {
  FaTwitter,
  FaWhatsapp,
  FaRegEnvelope,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn
} from 'react-icons/fa';

const FooterTwo = (props) => {
  const SocialShare = [];
  if (props.config.facebook) {
    SocialShare.push({
      Social: <FaFacebookF/>,
      link: props.config.facebook,
    });
  }
  if (props.config.whatsapp) {
    SocialShare.push({
      Social: <FaWhatsapp/>,
      link: `wa.me/${props.config.whatsapp}`,
    });
  }
  if (props.config.email) {
    SocialShare.push({
      Social: <FaRegEnvelope/>,
      link: `mailto:${props.config.email}`,
    });
  }
  if (props.config.linkedin) {
    SocialShare.push({
      Social: <FaLinkedinIn/>,
      link: props.config.linkedin,
    });
  }
  if (props.config.instagram) {
    SocialShare.push({
      Social: <FaInstagram/>,
      link: props.config.instagram,
    });
  }
  if (props.config.twitter) {
    SocialShare.push({
      Social: <FaTwitter/>,
      link: props.config.twitter,
    });
  }
  return (
    <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12">*/}
          {/*  <div className="inner">*/}
          {/*    <div className="logo text-center text-sm-left mb_sm--20">*/}
          {/*      <a href="/">*/}

          {/*        <img src="/assets/images/logo/thaka.webp" alt="Logo images"/>*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share  d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>Copyright © 2020 Thaka Jordan. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
