import React from 'react';

const enTranslations = {
  // Menu
  'navHome': 'Home',
  'navAbout': 'About Thaka',
  'navAboutUCMAS': 'About UCMAS',
  'navNearest': 'Nearest',
  'navCenter': 'Center',
  'navStudentCorner': 'Student  Corner',
  'navMediaCenter': 'Gallery',
  'navContact': 'Contact Us',
  // Slider
  'sliderTitle': 'THAKA',
  'sliderSubTitle': 'The future starts NOW!',
  // Programs
  'readMore': 'Read More..',
  //Global
  'About': 'About',
  'READY TO DO THIS': 'READY TO DO THIS?',
  'Lets get to work': 'Lets get to work!',
  'Join our team': 'Join our team',
  'Register a student': 'Register a student',
  'Gallery': 'Gallery',
  'get in touch with us': 'Contact Us',
  'Contact With Phone Number': 'Contact With Phone Number',
  'Email Address': 'Email Address',
  'Location':'Location',
  'Managing Team' : 'Managing Team',
  'Send Us Message':'Send us a message',
  'First Name' : 'First Name',
  'Enter your first name' : 'Enter your first name',
  'Last Name' : 'Last Name',
  'Enter your last name' : 'Enter your last name',
  'Enter a valid email address' : 'Enter a valid email address',
  'Phone Number' : 'Phone Number',
  'Enter phone number' : 'Enter phone number',
  'Subject' : 'Subject',
  'Enter message subject' : 'Enter message subject',
  'Message' : 'Message',
  'Send Message' : 'Send Message',




};

export default enTranslations;
