import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../elements/common/Breadcrumb';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import CallAction from '../elements/callaction/CallAction';
import FooterTwo from '../component/footer/FooterTwo';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import localize from '../localize';


class About extends Component {
  constructor(props) {
    super(props);
    this.programs = props.programs;
    this.config = props.config;
    this.i18 = props.t;
  }

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle={this.i18('About')}/>

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                programs={this.programs}/>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--60 pb--110 bg_image "
             data-black-overlay="5"
             style={{ backgroundImage: `url(${((this.config || {}).aboutPageCoverImage || {}).largeUrl || ((this.config || {}).aboutPageCoverImage || {}).originalUrl})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className=" text-center pt--60 breadcrumb-inner">
                  <h2 className="title ">{this.i18('About')}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="bodyE">
              <div className="row row--35 align-items-center">
                <div className={(i18n.language === 'en') ? "col-lg-12 text-left" : "col-lg-12 text-right" }
                     dangerouslySetInnerHTML={{ __html: (localize(this.config.about )) }}>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start call To Action  */}
        <CallAction/>
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo config={this.config}/>
        {/* End Footer Style  */}

      </React.Fragment>
    );
  }
}

export default withTranslation()(About);
