import React, { Component, Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import Slider from 'react-slick';
import { slideSlick } from '../page-demo/script';
import Header from '../component/header/HeaderFive';
import FooterTwo from '../component/footer/FooterTwo';
import CallAction from '../elements/callaction/CallAction';
import Helmet from '../component/common/Helmet';
import { FiChevronUp } from 'react-icons/fi';
import axios from 'axios';
import { Link } from 'react-router-dom';
import localize from '../localize';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isJoinOpen: false,
    };
    this.teamMembers = [];
    this.programs = props.programs;
    this.schoolProgram = this.getSchoolProgram();
    this.config = props.config;
    this.i18 = props.t;
    this.openModal = this.openModal.bind(this);
  }

  actionView = React.createRef();

  getSchoolProgram() {
    const schoolPrograms =  this.programs.filter((program) => program.schoolProgram);
    if (schoolPrograms.length > 0) {
      return schoolPrograms[0];
    } else {
      return null;
    }
  }

  openModal() {
    this.setState({ isJoinOpen: true });
  }

  render() {


    // var namesItemOne = [
    //   'The Philosophy Of business analytics',
    //   'Fast-Track Your business',
    //   'Lies And Damn Lies About business',
    //   'The Ultimate Deal On business',
    // ];
    // var namesItemTwo = [
    //   'Proof That business Really Works',
    //   'Here Is What You Should Do For Your business',
    //   'The Hidden Mystery Behind business',
    // ];
    return (
      <Fragment>
        <Helmet pageTitle={this.i18('Home')}/>

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                programs={this.programs}/>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className={'rn-slick-dot dot-light homePageSliderStyle'} {...slideSlick}>
              {((this.config || {}).homePageCoverImages || []).map((coverImage) => (
                <div key={coverImage._id}>
                  <div
                    className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image`}
                    style={{
                      backgroundImage: `url(${coverImage.largeUrl || coverImage.originalUrl})`,
                      direction: 'rtl !important'
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className={(i18n.language === 'en') ? 'inner text-left' : 'inner text-right'}>
                            <h1 className="title">
                              {/*{this.i18('sliderTitle')}*/}
                            </h1>
                            <p className="description">
                              {/*{this.i18('sliderSubTitle')}*/}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div
              className={(i18n.language === 'en') ? 'row row--35 align-items-center text-left' : 'row row--35 align-items-center text-right'}>
              <div className="col-lg-12 ck-content "
                   dangerouslySetInnerHTML={{ __html: (localize(this.config.homePageAbout)) }}>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">

              {/* Start Single Service  */}
              <div className="col-lg-12">
                <div
                  className={(i18n.language === 'en') ? 'row text-left justify-content-around' : 'row text-right justify-content-around'}>
                  {this.programs.map((program, index) => (
                    <div className="col-lg-3 col-md-3 mt--30" key={index}>
                      <div className="standard-service">
                        <div className="thumbnai">
                          <img
                            src={((program || {}).image || {}).largeUrl || ((program || {}).image || {}).originalUrl}
                            alt={localize(program.name)}/>
                        </div>
                        <div className="content">
                          <h3><a href={'/programs/' + program._id}>{localize(program.name)}</a></h3>
                          <p>{localize(program.shortDescription)
                            .substr(0, 250)}</p>
                          <a className="btn-transparent rn-btn-dark"
                             href={'/programs/' + program._id}><span
                            className="text">{this.i18('readMore')}</span></a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
              {/* End Single Service  */}

            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start About Area  */}
        {/*<div className="rn-about-area ptb--120 bg_color--5">*/}
        {/*  <div className="container">*/}
        {/*    <div className="row row--35 align-items-center">*/}
        {/*      <div className="col-lg-6">*/}
        {/*        <div className="thumbnail">*/}
        {/*          <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="col-lg-6">*/}
        {/*        <div className="about-inner inner">*/}
        {/*          <div className="section-title">*/}
        {/*            <h2 className="title">Working Process</h2>*/}
        {/*            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim exercitationem*/}
        {/*              impedit iure quia quo recusandae?</p>*/}
        {/*          </div>*/}
        {/*          <div className="accordion-wrapper mt--30">*/}
        {/*            <Accordion01/>*/}
        {/*          </div>*/}
        {/*          <div className="about-button mt--50">*/}
        {/*            <a className="rn-button-style--2 btn-solid" href="/about">See how it works</a>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* End About Area  */}

        {/* Start call To Action  */}
        <CallAction
          showContactUs={false}
          showStudentForm={true}
          defaultProgram={(this.schoolProgram || {})._id}
          selectedProgramName={localize((this.schoolProgram || {}).name)}
          showSchoolForm={this.schoolProgram}/>
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo config={this.config}/>
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default withTranslation()(Home);
