import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import Breadcrumb from '../elements/common/Breadcrumb';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import FooterTwo from '../component/footer/FooterTwo';
import JoinOurTeamForm from './JoinTeamForm';

class joinOurTeam extends Component {
  constructor(props) {
    super(props);
    this.programs = props.programs;
    this.config = props.config;
  }

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='Join Our Team'/>

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                programs={this.programs}/>
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <Breadcrumb title={'Join Our Team'}/>
        {/* End Breadcrump Area */}

        {/* Start Form Area  */}
        <div className="contact-form--1">
          <div className="container">
            <JoinOurTeamForm programs={this.programs} />
          </div>
        </div>
        {/* End Form Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start call To Action  */}
        {/*<CallAction/>*/}
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo config={this.config}/>
        {/* End Footer Style  */}

      </React.Fragment>
    );
  }
}

export default joinOurTeam;
