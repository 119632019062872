import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';
import localize from '../../localize';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class HeaderFive extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.programs = props.programs;
    this.i18 = props.t;
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', function () {
      console.log('All assets are loaded');
    });
  }

  menuTrigger() {
    document.querySelector('.header-wrapper')
      .classList
      .toggle('menu-open');
  }

  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper')
      .classList
      .remove('menu-open');
  }

  render() {
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu')
            .classList
            .toggle('active');
          this.classList.toggle('open');
        };
      }
    }

    const {
      color,
      headerPosition
    } = this.props;
    const activeStyle = {
      borderBottom: '3px solid gold'
    };


    let logoUrl = <img src="/assets/images/logo/thaka.webp" alt="THAKA"/>;
    // if (logo === 'light') {
    //   logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Trydo"/>;
    // } else if (logo === 'dark') {
    //   logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Trydo"/>;
    // } else if (logo === 'symbol-dark') {
    //   logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Trydo"/>;
    // } else if (logo === 'all-dark') {
    //   logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo"/>;
    // } else if (logo === 'symbol-light') {
    //   logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Trydo"/>;
    // } else {
    //   logoUrl = <img src="/assets/images/logo/logo.png" alt="Trydo"/>;
    // }

    return (
      <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-6">
                <div className={(i18n.language === 'en')? "header-left" :  "header-right"}>
                  <div className="logo">
                    <a href="/">
                      {logoUrl}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-6">
                <div className={(i18n.language === 'en')? "header-right justify-content-end" :  "header-right   justify-content-start"}>
                  <nav className="mainmenunav d-lg-block">

                    <ul className="mainmenu">
                      <li><NavLink to="/" activeStyle={activeStyle} exact>{this.i18('navHome')}</NavLink></li>
                      <li><NavLink activeStyle={activeStyle} to="/about">{this.i18('navAbout')}</NavLink></li>
                      {this.programs.map((program) => {
                        if (program.hasLocations || program.hasStudentCorner) {
                          return (
                            <li key={program._id} className="has-droupdown"><NavLink
                              to="#">{localize(program.name)}</NavLink>
                              <ul className="submenu">
                                <li><NavLink to={'/programs/' + program._id}>{this.i18('About') +' '+ localize(program.name)}</NavLink></li>
                                {program.hasLocations && (
                                  <li><NavLink
                                    to={'/locations/' + program._id}>{this.i18('navNearest') + ' ' + localize(program.name) +  ' ' +this.i18('navCenter')} </NavLink>
                                  </li>
                                )}
                                {program.hasStudentCorner && (
                                  <li><NavLink to={'/studentCorner/' + program._id}>{this.i18('navStudentCorner')}</NavLink></li>
                                )}
                              </ul>
                            </li>
                          );
                        } else {
                          return (
                            <li key={program._id}><NavLink activeStyle={activeStyle}
                                                           to={'/programs/' + program._id}>{localize(program.name)}</NavLink>
                            </li>);
                        }
                      })}

                      <li><NavLink activeStyle={activeStyle} to="/mediaCenter">{this.i18('navMediaCenter')}</NavLink></li>
                      <li><NavLink activeStyle={activeStyle} to="/contact">{this.i18('navContact')}</NavLink></li>

                      <li className={'d-block d-lg-none'}>
                        <button className="rn-btn" onClick={()=>{
                          (i18n.language === 'en')? i18n.changeLanguage('ar'):  i18n.changeLanguage('en');
                        } }>
                          {(i18n.language === 'en')? 'العربية': 'English'}

                        </button>
                      </li>


                    </ul>

                  </nav>
                  <div className="header-btn d-none d-lg-block">
                    <button className="rn-btn" onClick={()=>{
                      if(i18n.language === 'ar'){
                        i18n.changeLanguage('en');
                        document.body.dir = 'ltr';
                      }else{
                        i18n.changeLanguage('ar');
                        document.body.dir = 'rtl';
                      }

                    } }>
                      {(i18n.language === 'en')? 'العربية': 'English'}

                    </button>
                  </div>


                  {/* Start Humberger Menu  */}
                  <div className="humberger-menu d-block d-lg-none pl--20 pr--20">
                    <span onClick={this.menuTrigger}
                          className="menutrigger text-white"><FiMenu/></span>
                  </div>
                  {/* End Humberger Menu  */}
                  <div className="close-menu d-block d-lg-none">
                    <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX/></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withTranslation()(HeaderFive);
