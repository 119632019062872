import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";
import Header from '../component/header/HeaderFive';

 class Error404 extends Component {
   constructor(props) {
     super(props);
     this.programs = props.programs;
     this.config = props.config;
   }
    render() {
        return (
            <>

              {/* Start Header Area  */}
              <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
                      programs={this.programs}/>
              {/* End Header Area  */}

                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    {/*<h1 className="title theme-gradient">404!</h1>*/}
                                    <h3 className="sub-title">Page not found</h3>
                                    <span>The page you were looking for could not be found.</span>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">Back To Homepage</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer config={this.config} />
            </>
        )
    }
}
export default Error404;
