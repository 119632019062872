import React, { Component, useEffect, useState } from 'react';
import PageHelmet from '../component/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiHeadphones, FiMapPin, FiPhone } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import CallAction from '../elements/callaction/CallAction';
import FooterTwo from '../component/footer/FooterTwo';
import axios from 'axios';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { withTranslation } from 'react-i18next';
import localize from '../localize';
import i18n from 'i18next';
import { getLocations, getProgram } from '../apiServices';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Locations(props) {

  const i18 = props.t;
  const config = props.config;
  const [isLoading, setIsLoading] = useState(true);
  const [mapCenter , setMapCenter ]  = useState({
    lat: 31.9539,
    lng: 35.9106,
  });
  const [program, setProgram] = useState([]);
  const [locations, setLocations] = useState([]);
  const programId = props.match.params.programId;


  if (isLoading) {

    getProgram(programId)
      .then((response) => {
        setProgram(response);
        getLocations(programId)
          .then((response) => {
            setLocations(response.results);
          });
      });
    setIsLoading(false);
  }

  console.log(locations);
  useEffect(() => {
    let bounds = new props.google.maps.LatLngBounds();
    if (locations != null) {
      for (let i = 0; i < locations.length; i++) {
        const location = locations[i];
        bounds.extend({
          lat: location.geopoint.coordinates[1],
          lng: location.geopoint.coordinates[0]
        });
      }
    }

  }, [locations]);

  const viewMarkerInfoWindow = (location) => {
    console.log(location);
  };
  const changeMapCenter = (location) => {
    setMapCenter({
      lat: location.geopoint.coordinates[1],
      lng: location.geopoint.coordinates[0]
    });

  };

  return (
    <React.Fragment>
      <PageHelmet pageTitle="Centers"/>

      {/* Start Header Area  */}
      <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"
              programs={props.programs}/>
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <div className="rn-page-title-area pt--60 pb--110 bg_image "
           data-black-overlay="5"
           style={{ backgroundImage: `url(${((program || {}).coverImage || {}).largeUrl || ((program || {}).coverImage || {}).originalUrl})` }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className=" text-center pt--60 breadcrumb-inner">
                <h2 className="title ">{localize((program || {}).name)}</h2>
                <p
                  style={{ color: 'white' }}>{localize((program || {}).shortDescription)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Contact Map  */}

      {(locations != null) ? (
        // MAP Container
        <div className={'p-5 row'}>
          <div className={'col-3'} style={{
            maxHeight: '100vh',
            overflow: 'auto'
          }}>
            {(locations || []).map((item) => (
              <div  style={{cursor: 'pointer'}} onClick={()=>changeMapCenter(item)} >
                <h4 >{localize(item.name)}</h4>
                {item.address && (<h6><FiMapPin/> {localize(item.address)}</h6>)}
                {(item.phoneNumbers || []).map((locationPhone) => (
                  <a target={'_blank'} href={'tel:' + locationPhone.value}><h6><FiPhone/> {locationPhone.label}</h6></a>
                ))}
                <hr style={{ background: '#ababab' }}/>
              </div>
            ))}
          </div>
          <div className={'col-9'}>
            <Map
              google={props.google}
              initialCenter={{
                lat: 31.984690983601308,
                lng: 35.83280895566108
              }}
              center={mapCenter}
              zoom={15}
            >
              {(locations || []).map((singleLocation) => (
                <Marker
                  onClick={() => viewMarkerInfoWindow(singleLocation)}
                  key={singleLocation._id}
                  position={{
                    lat: singleLocation.geopoint.coordinates[1],
                    lng: singleLocation.geopoint.coordinates[0]
                  }}
                  name={localize(singleLocation.name)}
                >
                  {/*<InfoWindow*/}
                  {/*  visible={true}*/}
                  {/*  // onClose={() => {*/}
                  {/*  //   this.selectedLocation = null;*/}
                  {/*  //   this.setState(this.state);*/}
                  {/*  // }}*/}
                  {/*  google={props.google}>*/}
                  {/*  <div>*/}
                  {/*    <h1>{localize(singleLocation.name)}</h1>*/}
                  {/*  </div>*/}
                  {/*</InfoWindow>*/}
                </Marker>
              ))}

            </Map>

          </div>
        </div>
      ) : (
        <div className={'p-5 text-center'}>
          <h3>There is not any location for this program till now. </h3>
        </div>
      )}

      {/* End Contact Map  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start call To Action  */}

      {/* End call To Action  */}

      {/* Start Footer Style  */}
      <FooterTwo config={config}/>
      {/* End Footer Style  */}

    </React.Fragment>
  );


}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAhyetD7gcDe4Q-b84X9NDVKbr8Me5aADk'
})(withTranslation()(Locations));
